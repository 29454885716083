import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from '../Home/Home';
import Products from '../Products/Products';
import Detail from '../Products/Detail/Detail';
import Line from '../Products/Line/Line';
import About from '../CustomPages/About/About';
import Contact from '../CustomPages/Contact/Contact';
import Providers from '../CustomPages/Providers/Providers';
import Representatives from '../CustomPages/Representatives/Representatives';
import Error404 from '../Error404/Error404';

function App() {
  return (
    <Routes>
      <Route path='*' element={< Error404 />} />
      <Route path='/' element={<Home />} />
      <Route path='/products' element={<Products />} />
      <Route path='/products/:id' element={<Detail />} />
      <Route path='/products/line/:id' element={<Line />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/providers' element={<Providers />} />
      <Route path='/representatives' element={<Representatives />} />
    </Routes>
  );
}

export default App;