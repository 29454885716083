import React from "react";

import './About.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

import aboutBanner from '../../../assets/img/banner/aboutBanner.jpg';
import aboutCountriesBanner from '../../../assets/img/banner/aboutCountriesBanner.jpeg';

import arg from '../../../assets/img/countries/ararg.svg';
import uy from '../../../assets/img/countries/uy.svg';
import cl from '../../../assets/img/countries/cl.svg';
import bo from '../../../assets/img/countries/bo.svg';
import pe from '../../../assets/img/countries/pe.svg';
import ec from '../../../assets/img/countries/ec.svg';
import co from '../../../assets/img/countries/co.svg';
import py from '../../../assets/img/countries/py.svg';
import mx from '../../../assets/img/countries/mx.svg';
import us from '../../../assets/img/countries/us.svg';

import medix from '../../../assets/img/prov/medix.jpg';
import biogenesis from '../../../assets/img/prov/biogenesis.png';
import mdk from '../../../assets/img/prov/mdk.png';
import mennen from '../../../assets/img/prov/mennen.png';
import sle from '../../../assets/img/prov/sle.png';
import vathin from '../../../assets/img/prov/vathin.png';
import vivolight from '../../../assets/img/prov/vivolight.png';

function About() {
    return (
        <div className="about">
            <Header />
            <div className="aboutContent">
                <div className="aboutBanner">
                    <img className="aboutBannerImg" src={aboutBanner} alt='aboutBannerImg'></img>
                    <h3 className="aboutSubtitle">Acerca de</h3>
                    <h1 className="aboutTitle">Xidem Medical</h1>
                </div>
                <div className="aboutBoxes">
                    <div className="aboutBox">
                        <h2 className="aboutBoxTitle">Quiénes somos</h2>
                        <p className="aboutBoxText">Xidem, creada en 2020 con sede en Florida (USA), está orientada a acercar la mejor tecnología disponible para la salud a los 5 continentes.</p>
                    </div>
                    <div className="aboutBox">
                        <h2 className="aboutBoxTitle">Visión</h2>
                        <p className="aboutBoxText">Facilitar el acceso a equipamiento médico, materias primas, materiales y repuestos de alta calidad a distribuidores, fabricantes, desarrolladores relacionados con la salud humana y animal.</p>
                    </div>
                    <div className="aboutBox">
                        <h2 className="aboutBoxTitle">Misión</h2>
                        <p className="aboutBoxText">Ser el nexo entre proveedores y clientes de la mejor solución en la distribución, fabricación y manutención de equipamiento médico</p>
                    </div>
                </div>
                <div className="aboutCountriesContainer">

                    <div className="aboutCountries">

                        <h1 className="aboutCountriesTitle">¿Dónde estamos?</h1>

                        <div className="aboutCountriesFullList">

                            <img className="countryFlag1" src={us} alt="us"></img>
                            <p className="countryName">USA</p>

                            <div className="aboutCountriesList">

                                <div className="flagList1">
                                    <img className="countryFlag" src={mx} alt="mx"></img>
                                    <p className="countryName">México</p>

                                    <img className="countryFlag" src={cl} alt="cl"></img>
                                    <p className="countryName">Chile</p>

                                    <img className="countryFlag" src={uy} alt="uy"></img>
                                    <p className="countryName">Uruguay</p>
                                </div>

                                <div className="flagList2">
                                    <img className="countryFlag" src={co} alt="co"></img>
                                    <p className="countryName">Colombia</p>
                                    <img className="countryFlag" src={pe} alt="pe"></img>
                                    <p className="countryName">Perú</p>
                                    <img className="countryFlag" src={bo} alt="bo"></img>
                                    <p className="countryName">Bolivia</p>
                                </div>

                                <div className="flagList3">


                                    <img className="countryFlag" src={arg} alt="arg"></img>
                                    <p className="countryName">Argentina</p>
                                    <img className="countryFlag" src={ec} alt="ec"></img>
                                    <p className="countryName">Ecuador</p>
                                    <img className="countryFlag" src={py} alt="py"></img>
                                    <p className="countryName">Paraguay</p>
                                </div>

                            </div>

                        </div>
                    </div>

                    <img className="aboutCountriesBanner" src={aboutCountriesBanner} alt="aboutCountriesBanner"></img>

                    <div className="aboutCountriesProv">
                        <h1 className="provTitle">Nuestros Proveedores</h1>
                        <div className="provList">
                            <img className="provLogo" src={sle} alt="sle"></img>
                            <img className="provLogoM" src={mennen} alt="mennen"></img>
                            <img className="provLogo" src={mdk} alt="mdk"></img>
                            <img className="provLogo" src={vathin} alt="vathin"></img>
                            <img className="provLogo" src={vivolight} alt="vivolight"></img>
                            <img className="provLogo" src={biogenesis} alt="biogenesis"></img>
                            <img className="provLogo" src={medix} alt="medix"></img>
                        </div>
                    </div>

                </div>

                <div className="aboutValues">
                    <h1 className="aboutValuesTitle">Nuestros principales valores</h1>
                    <div className="aboutValuesContent">
                        <p className="aboutValuesText">Excelencia en la ejecución de procesos. Confianza lograda a través de relaciones a largo plazo. Pasión por lo que hacemos.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;