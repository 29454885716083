import React from "react";

import './Contact.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

function Contact() {
    return (
        <div className="contact">
            <Header />
            <div className="contactContent">
                <h1 className="contactTitle">CONTACTO</h1>
                <div className="contactBox">
                    <p className="contactEmail">ventas@xidem.com</p>
                    <div className="contactNumbers">
                        <div className="contact1">
                            <h2 className="contactSubtitle">Ventas</h2>
                            <p className="contactNumber">Texto al +1 (954) 686 4810</p>
                        </div>
                        <div className="contact1">
                            <h2 className="contactSubtitle">Finanzas</h2>
                            <p className="contactNumber">+54 9 11 6457 1635</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;