import React from "react";
import { useParams, Link } from "react-router-dom";

import lines from '../../../assets/database/lines.json';
import products from '../../../assets/database/products.json';

import './Line.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

function Line() {
    const { id } = useParams();
    const lineData = lines[id];

    return (
        <div className="line">
            <Header />
            <div className="lineContent">
                <div className="lineBanner">
                    <h1 className="lineTitle">{lineData.name}</h1>
                </div>
                <div className="lineProducts">
                    {products.map((product, i) => {
                        if (product.lineId === lineData.id)
                            return (
                                <Link key={i} to={`/products/${product.id}`} className="lineLink">
                                    <div className="lineProductsBox">
                                        <h2 className="lineProductTitle">{product.model}</h2>
                                        <img className="lineProductImg" src={require(`../../../assets/img/products/${product.model}.jpg`)} alt="lineProductImg"></img>
                                        <p className="lineProductDescription">{product.descShort}</p>
                                    </div>
                                </Link>
                            )
                        return (
                            null
                        )
                    }
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Line;