import React from "react";
import { Link } from 'react-router-dom';
import { useState } from "react";

import './Header.css';

function Header() {
    const [fix, setFix] = useState(false);
    function setSticky() {
        if(window.scrollY >= 0.1) {
            setFix(true);
        } else {
            setFix(false);
        };
    };

    window.addEventListener("scroll", setSticky);

    return (
        <div className={fix ? 'header fixed' : 'header'}>
            <div className="rowA">
                <div className="logo">
                    <Link to='/' className="linkLogo">
                        <h1 className="logoText">XIDEM MEDICAL</h1>
                    </Link>
                </div>
                <div className="navbarA">
                    <div className="navbarLinks">
                        <Link to='/products' className="linksA">
                            <p className="navbarAText">PRODUCTOS</p>
                        </Link>
                        <Link to='/contact' className="linksA">
                            <p className="navbarAText">CONTACTO</p>
                        </Link>
                        <Link to='/about' className="linksA">
                            <p className="navbarAText">ACERCA DE XIDEM</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mobileHeader">
                <div className="logoArea">
                    <div className="logo">
                        <Link to='/home' className="linkLogo">
                            <h1 className="logoText">XIDEM MEDICAL</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;