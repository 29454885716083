import React from "react";

import './Representatives.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

function Representatives() {
    return (
        <div className="representatives">
            <Header />
            <p>Representatives</p>
            <Footer />
        </div>
    );
}

export default Representatives;