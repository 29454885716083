import React from "react";

import { Link } from "react-router-dom";

import './ProductShowcase.css';

import products from '../../../assets/database/products.json';

function ProductShowcase() {
    return (
        <div className="productShowcase">
            <div className="showcaseContainer">
                <h1 className="showcaseTitle">PRODUCTOS DESTACADOS</h1>
                <div className="showcaseBoxContainer">
                    <div className="showcaseBox">
                        <Link to='/products/0' className="showcaseLink">
                            <div className="showcaseImgContainer">
                                <img className="showcaseImg" src={require(`../../../assets/img/products/${products[0].model}.jpg`)} alt="showcaseImg"></img>
                            </div>
                            <h2 className="showcaseModel">{products[0].model}</h2>
                            <h3 className="showcaseBrand">{products[0].brand}</h3>
                            <p className="showcaseLine">{products[0].line}</p>
                            <p className="showcaseDesc">{products[0].descShort}</p>
                        </Link>
                    </div>
                    <div className="showcaseBox">
                        <Link to='/products/1' className="showcaseLink">
                            <div className="showcaseImgContainer">
                                <img className="showcaseImg" src={require(`../../../assets/img/products/${products[1].model}.jpg`)} alt="showcaseImg"></img>
                            </div>
                            <h2 className="showcaseModel">{products[1].model}</h2>
                            <h3 className="showcaseBrand">{products[1].brand}</h3>
                            <p className="showcaseLine">{products[1].line}</p>
                            <p className="showcaseDesc">{products[1].descShort}</p>
                        </Link>
                    </div>
                    <div className="showcaseBox">
                        <Link to='/products/o' className="showcaseLink">
                            <div className="showcaseImgContainer">
                                <img className="showcaseImg" src={require(`../../../assets/img/products/${products[0].model}.jpg`)} alt="showcaseImg"></img>
                            </div>
                            <h2 className="showcaseModel">{products[0].model}</h2>
                            <h3 className="showcaseBrand">{products[0].brand}</h3>
                            <p className="showcaseLine">{products[0].line}</p>
                            <p className="showcaseDesc">{products[0].descShort}</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductShowcase;