import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import products from '../../../assets/database/products.json';

import './Detail.css';

import Header from '../../Partials/Header/Header';
import ProductShowcase from "../../Home/ProductShowcase/ProductShowcase";
import Footer from '../../Partials/Footer/Footer';

function Detail() {
    const { id } = useParams();
    const product = products[id];
    const description = product.descItems;

    return (
        <div className="detail">
            <Header />
            <div className="detailContent">
                <div className="detailImgContainer">
                    <img className="detailImg" src={require(`../../../assets/img/products/${product.model}.jpg`)} alt="detailImg"></img>
                    <Link to={product.brochure} className="detailLink" target="_blank">
                        <h1 className="detailFolleto">Ver folleto</h1>
                    </Link>
                    <img className="detailImg2" src={require(`../../../assets/img/products/${product.model}2.jpg`)} alt="detailImg2"></img>
                </div>
                <div className="detailSide">
                    <div className="detailContainer">
                        <h2 className="detailTitle">{product.brand}</h2>
                        <h3 className="detailSubtitle">{product.model}</h3>
                        <p className="detailSeparator">___________________________</p>
                        <Link to='/products/line/0' className="detailLink">
                            <p className="detailLine">{product.line}</p>
                        </Link>
                        <p className="descShort">{product.descShort}</p>
                    </div>
                    <div className="detailContainer2">
                        <div className="descriptionList">
                            <h3 className="detailDescriptionTitle">{product.descSubtitle}</h3>
                            <ul>
                                {description.map((item, i) => <li key={i + item}>{item}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detailFooter">
                <h1 className="detailFooterTitle">Conozca más sobre este producto</h1>
                <Link to={product.brochure} className="detailLink" target="_blank">
                    <h1 className="detailFolleto2">Descargar folleto informativo</h1>
                </Link>
            </div>
            <ProductShowcase />
            <Footer />
        </div>
    );
}

export default Detail;