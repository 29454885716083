import React from "react";
import { Link } from 'react-router-dom';

import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="footerContent">
                <div className="footerLinksContainer">
                    <Link to='/products' className="footerLinks">
                        <p className="footerNavbarText">Productos</p>
                    </Link>
                    <Link to='/about' className="footerLinks">
                        <p className="footerNavbarText">Quienes somos</p>
                    </Link>
                    <Link to='/contact' className="footerLinks">
                        <p className="footerNavbarText">Contacto</p>
                    </Link>
                </div>
                <Link to='/home' className="logoLink">
                    <h1 className="footerLogo">XIDEM MEDICAL</h1>
                </Link>
                <div className="footerTrademark">© 2023  Xidem Medical LLC. - All Rights Reserved.</div>
            </div>
        </div>
    );
}

export default Footer;