import React from "react";

import './Home.css';

import Header from '../Partials/Header/Header';
// import ProductShowcase from '../Home/ProductShowcase/ProductShowcase';
import Banner from '../Home/Banner/Banner';
import Promo from '../Home/Promo/Promo';
import Footer from '../Partials/Footer/Footer';

function Home() {
  return (
    <div className="home">
      <Header />
      <Banner />
      {/* <ProductShowcase /> */}
      <Promo />
      <Footer />
    </div>
  );
}

export default Home;