import React from "react";
import { Link } from "react-router-dom";

import './Error404.css';

import Header from '../Partials/Header/Header';
import Footer from '../Partials/Footer/Footer';

function Error404() {
    return (
        <div className="error404">
            <Header />
            <div className="errorContainer">
                <p className="errorText">Error 404 - Página no encontrada</p>
                <Link to='/' className="errorLink">
                    <button className="errorButton">Volver al Inicio</button>
                </Link>
            </div>
            <Footer />
        </div>
    );
}

export default Error404;