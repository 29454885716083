import React from "react";

import './Providers.css';

import Header from '../../Partials/Header/Header';
import Footer from '../../Partials/Footer/Footer';

function Providers() {
    return (
        <div className="providers">
            <Header />
            <p>Providers</p>
            <Footer />
        </div>
    );
}

export default Providers;