import React from "react";
import { Link } from 'react-router-dom';

import './Banner.css';

import banner from '../../../assets/img/banner/aboutBanner.jpg';

function Banner() {
    return (
        <div className="banner">
            <img className="bannerImg" src={banner} alt='bannerImg'></img>
            <div className="bannerText">
                <h1 className="bannerTitle">Soluciones en tecnología médica</h1>
                <h3 className="bannerSubtitle">Proveedores de equipamiento de alta calidad</h3>
                <Link to='/products' className="bannerLink">
                    <button className="bannerButton">Ver más</button>
                </Link>
            </div>
        </div>
    );
}

export default Banner;